import { createSignal, splitProps, For, JSX } from "solid-js";
import { Avatar, Typography,   IconButton, Stack, Card, Divider, Box,  Grid } from "@suid/material";

import { LooksOneOutlinedIcon, LooksTwoOutlinedIcon, CloseIcon, CalendarIcon, RateIcon, HoursIcon,
    AskForIcon, ContractIcon, ProposalIcon } from "../components/icons"
import { AvatarChipWithIcon, WFChip } from '../components/SmallComponents/SmallChips';
import { AccountMenu } from '../components/AppMenuBar/AppBarMenu';
import { WFStatusComponent }from '../components/SmallComponents/SmallWFStatusComponent';
import { RatingComment } from '../components/SmallComponents/SmallShowRatingComment';
import { WFStatusFooter } from '../components/SmallComponents/SmallShowWFStatusFooter';
import { ShowHeading } from '../components/SmallComponents/SmallHeader'
import { useNavigate } from "@solidjs/router";
import { isLoggedIn } from '../../utils/isloggedin'
import { isExtraSmall, isSmall } from "../../utils/sizeUtil"

import { RateValueForTable } from '../components/SmallComponents/SmallRateValue';
import { getText } from '../components/SmallComponents/Translate'

type RowData = {
    icon: JSX.Element;
    name: string;
    rate: number;
    country: string;
    currency: string;
    rateModel: string;
    startdate: string;
    enddate: string;
    hours: number;
    hoursonsite: number;
};

const BasicTable = () => {

    const txtAgreed = getText("candidatepage", "agreed")
    const txtProposal = getText("candidatepage", "proposal")
    const txtCandidateAskfor = getText("candidatepage", "candidateaskfor")
    const txtRate = getText("smallcomponent", "rate")
    const txtStartdate = getText("contractcomponent", "header")
    const txtEnddate = getText("smallcomponent", "enddate")
    const txtHours = getText("smallcomponent", "hours")
    const txtWeek = getText("smallcomponent", "week")
    const txtOnsite = getText("smallcomponent", "onsite")
    const txtTotal = getText("default", "total")
    const txtPer = getText("smallcomponent", "per")
    const txtLoading = getText("default", "loading")

    const rows: RowData[] = [
        { icon: <ContractIcon />, name: txtAgreed() as string, rate: 9000, country: "NO", currency: "NOK", rateModel: "day" , startdate: "1/4/22", enddate: "31/1/22", hours: 43, hoursonsite: 23 },
        { icon: <ProposalIcon />, name: txtProposal() as string, rate: 9000, country: "DK", currency: "DKK", rateModel: "day", startdate: "1/4/22", enddate: "31/1/22", hours: 43, hoursonsite: 23 },
        { icon: <AskForIcon />, name: txtCandidateAskfor() as string, rate: 1350, country: "NO", currency: "NOK", rateModel: "hour", startdate: "1/6/22", enddate: "31/12/22", hours: 43, hoursonsite: 23 },
    ];

    type CustomGridItemProps = {
        title: string;
        icon: JSX.Element;
        children: JSX.Element;
    };

    const CustomGridItem = (props: CustomGridItemProps) => {
        const [local] = splitProps(props, ['children'])
        return (
            <>
                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Card sx={{ margin: 2 }}>
                        <Stack margin={1} spacing={0.5}>
                            <Typography variant="h5" align="center" gutterBottom>
                                {props.title}
                                {props.icon}
                            </Typography>
                            {props.children}
                        </Stack>
                    </Card>
                </Grid>
            </>
        )
    };

    // Helper function to centralize the logic for selecting the text based on the index
    const getTextByIndex = (index: number) => {
        switch (index) {
            case 0: return txtAgreed();
            case 1: return txtProposal();
            default: return txtCandidateAskfor();
        }
    };

    // Main component
    return (
        <>
            <Grid container >

                <CustomGridItem title={txtStartdate() as string} icon={<CalendarIcon />}>
                    <For each={rows} fallback={<div>{txtLoading()}</div>}>
                        {(row: RowData, index) => (
                            <>
                                <Typography variant="h5" gutterBottom>{row.name}</Typography>
                                <Typography variant="subtitle2" align="center">
                                    <b>{getTextByIndex(index())}</b>
                                </Typography>
                                <Typography variant="body2" align="center">
                                    {row.startdate} - {row.enddate}
                                </Typography>
                            </>
                        )}
                    </For>
                </CustomGridItem>

                <CustomGridItem title={txtHours() as string} icon={<HoursIcon />}>
                    <For each={rows} fallback={<div>{txtLoading()}</div>}>
                        {(row: RowData, index) => (
                            <>
                                <Typography variant="h5" gutterBottom>{row.name}</Typography>
                                <Typography variant="subtitle2" align="center">
                                    <b> {getTextByIndex(index())} </b>
                                </Typography>
                                <Typography variant="body2" align="center">
                                    {row.hours} {txtHours()} {txtPer()}  {txtWeek()} / {row.hoursonsite} {txtOnsite()} {txtHours()} {txtPer()} {txtWeek()}
                                </Typography>
                            </>
                        )}
                    </For>
                </CustomGridItem>

                <CustomGridItem title={txtRate() as string} icon={<RateIcon />}>
                    <For each={rows} fallback={<div>{txtLoading()}</div>}>
                        {(row: RowData, index) => (
                            <>
                                <Typography variant="subtitle2" align="center">
                                    <b>{getTextByIndex(index())} </b>
                                </Typography>
                                <RateValueForTable value={row.rate} country={row.country} currency={row.currency} primaryRateModel={row.rateModel as "hour" | "day"}  />  
                            </>
                        )}
                    </For>
                </CustomGridItem>

            </Grid>
        </>
    );

}


const ShowCustomerAssignment = () => {

    const navigate = useNavigate();

    if (!isLoggedIn()) {
        navigate('/login');
        return;
    }

    const [open, setOpen] = createSignal(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        window.history.go(-1); return false;
    }

    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);

    const txtHeader = getText("candidatepage", "detailsheader")
    const txtContract = getText("default", "contract")
    const txtInterview = getText("default", "interview")

    const txtCommentContract = getText("candidatepage", "commentcontract")
    const txtCoverletter = getText("candidatepage", "coverletter")
    const txtInterviewDate = getText("interviewcomponent", "interviewdate")
    const txtInterviewBy = getText("interviewcomponent", "interviewby")
    const txtInterviewedBy = getText("interviewcomponent", "interviewedby")
    const txtFeedback = getText("interviewcomponent", "feedback")
    const txtInternalComment = getText("candidatepage", "internalcomment")
    const txtCommenttoCandidate = getText("candidatepage", "commenttocandidate")
    const txtProposal = getText("default", "proposal")

    return (
        <>
            <AccountMenu />
            <ShowHeading pxValue={4} >{txtHeader()}</ShowHeading>

            <Divider />

            {/* Present Candidate Profile section for Customer */}
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card sx={{ margin: 2 }}>
                        <Stack direction="column" alignItems="top" justifyContent="baseline" sx={{ px: 2, display: 'flex' }} >
                            {/* Addition navigation options for the customer */}
                            <Box sx={{ margin: 1, display: 'flex', bgcolor: 'background.default'[200] }} >
                                <IconButton onClick={handleClose} > <CloseIcon /> </IconButton>
                            </Box>


                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={6} md={2} lg={2} xl={1}>

                                    <Avatar sx={{ margin: 2, display: 'flex', width: 100, height: 100 }} variant="rounded" src="/assets/imgs/avatar2.jpg" />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
                                    <Stack spacing={0.5}>
                                        <Typography variant="h4">Michael Scott</Typography>
                                        <Typography variant="h5">Project Manager</Typography>
                                    </Stack>
                                </Grid>

                                {/* Workflow Status for Assignment */}
                                <Grid item xs={12} sm={12} md={12} lg={8} xl={9}>
                                    <Card  >

                                        <WFStatusComponent type={"customer"}/>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Card>
                </Grid>


                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider />

                    <ShowHeading iconName="contract" >Discussion History</ShowHeading>

                    {/* Candidates current assignment for the customer */}
                    <Card sx={{ margin: 1 }}>
                        <Stack direction="column" alignItems="left" sx={{ px: 2, py: 1, bgcolor: 'background.default' }} >
                            {/* Price details */}
                            <Stack direction="column" spacing={1} sx={{ px: 2 }}>
                                <BasicTable />
                            </Stack>

                            <Card sx={{ margin: 1, bgcolor: 'background.default' }}>
                                {/* Contract Deviations */}
                                <RatingComment
                                    comment="1. Notice period for candidate is 30 days."
                                    header={txtCommentContract()}
                                    showDivider={false}
                                />
                                <RatingComment comment="2. Contract curency is NOK." />

                                {/* Comments to contract */}
                                <RatingComment
                                    comment="Show the cover letter. "
                                    header={txtCoverletter()}
                                />
                            </Card>
                            <WFStatusFooter type="customer" actionDate="18.3.22 7:48" userName="Sired Øystein" >{txtContract() as string}</WFStatusFooter>
                            <WFStatusFooter type="customer" actionDate="1.3.22 23:24" userName="Sired Øystein" >{txtProposal() as string}</WFStatusFooter>
                        </Stack>
                    </Card>

                    <ShowHeading iconName="interview" >{txtInterview()}</ShowHeading>

                    {/* Interview #1 */}
                    <Card sx={{ margin: 1 }}>
                        <Stack direction="column" alignItems="left" sx={{ px: 2, py: 1, bgcolor: 'background.default' }} >
                            <LooksOneOutlinedIcon />
                            <Stack direction="row" spacing={1} sx={{ px: 2 }} justifyContent="space-between">
                                <Stack direction={isExtraSmall() || isSmall() ? "column" : "row"} spacing={1} sx={{ px: 2 }} justifyContent="space-between">
                                    <Typography color="text.primary">{txtInterviewDate()}</Typography>

                                    <WFChip type="customer" wfCompleted={false} category="schedule" >10/2/22 9:33AM</WFChip>
                                </Stack>
                                <Stack direction={isExtraSmall() || isSmall() ? "column" : "row"} spacing={1} sx={{ px: 2 }} justifyContent="space-between">
                                    <Typography color="text.primary">{txtInterviewBy()}</Typography>

                                    <AvatarChipWithIcon url="/assets/imgs/avatar2.jpg" >Jim Nelson</AvatarChipWithIcon>
                                </Stack>
                            </Stack>
                            <Card sx={{ margin: 1, bgcolor: 'background.default' }}>
                                {/* Internal comments to interview */}
                                <RatingComment comment="He is realy good. Try to fix startdate." header={txtInternalComment()} />

                                {/* External comments to interview */}
                                <RatingComment comment="We would like to schedule a second and final interview. Invite will follw shortly." header="Comment to Candidate" />
                                <WFStatusFooter  type="customer" actionDate="10.2.22 14:01" userName="Stefan Hansen" >{txtFeedback() as string}</WFStatusFooter>
                            </Card>
                        </Stack>
                    </Card>



                    {/* Interview #2 */}
                    <Card sx={{ margin: 1, }}>
                        <Stack direction="column" alignItems="left" sx={{ px: 2, py: 1, bgcolor: 'background.default' }} >
                            <LooksTwoOutlinedIcon />
                            <Stack direction="row" spacing={1} sx={{ px: 2 }} justifyContent="space-between">
                                <Stack direction={isExtraSmall() || isSmall() ? "column" : "row"} spacing={1} sx={{ px: 2 }} justifyContent="space-between">
                                    <Typography color="text.primary">{txtInterviewDate()}</Typography>

                                    <WFChip  type="customer" wfCompleted={false} category="schedule" >12/2/22 11:00AM</WFChip>
                                </Stack>
                                <Stack direction={isExtraSmall() || isSmall() ? "column" : "row"} spacing={1} sx={{ px: 2 }} justifyContent="space-between">
                                    <Typography color="text.primary">{txtInterviewBy()}</Typography>

                                    <AvatarChipWithIcon url="/assets/imgs/avatar2.jpg" >Tany Smidth</AvatarChipWithIcon>
                                    <AvatarChipWithIcon url="/assets/imgs/avatar3.jpg" >Jim Nelson</AvatarChipWithIcon>
                                </Stack>
                            </Stack>
                            <Card sx={{ margin: 1, bgcolor: 'background.default' }}>
                                {/* Internal comments to interview */}
                                <RatingComment comment="Ready to offer a contract. Agreed to startdate 1/4-22"
                                    header={txtInternalComment() as string} />

                                {/* External comments to interview */}
                                <RatingComment
                                    comment="Thanks for taking your time to talk to us. We would like to offer your a contract. We will send your a proposal later today."
                                    header={txtCommenttoCandidate() as string} />
                            </Card>
                            <WFStatusFooter type="customer" actionDate="13.2.22 9:01" userName="Tany Smidth" >{txtFeedback() as string}</WFStatusFooter>
                        </Stack>
                    </Card>
                </Grid>

            </Grid >

        </>
    );
}

export { ShowCustomerAssignment }